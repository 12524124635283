<template>
  <v-row
    class="px-1 pt-2"
  >
    <!-- The user select-button -->
    <v-col
      cols="10"
      class="pt-1"
    >
      <v-overflow-btn
        v-model="chosenUser"
        segmented
        :items="membersList"
        :hint="'Logged in as ' + loggedInUser.display_name"
        persistent-hint
        :disabled="!formValid"
        color="primary"
        prepend-icon="mdi-send"
      >
        <template
          slot="item"
          slot-scope="data"
        >
          <v-list-item-avatar>
            <img
              v-if="!data.item.useIcon"
              :src="data.item.avatar"
              @error.stop.prevent="data.item.useIcon = true"
            >
            <v-icon v-else>
              mdi-face
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </v-overflow-btn>
    </v-col>

    <!-- The clear button -->
    <v-col
      v-if="showClear"
      cols="2"
      class="pl-1 pt-1"
    >
      <v-btn
        block
        dark
        depressed
        round
        color="grey darken-2"
        @click.native="emitClearClick"
      >
        Clear
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UserSelectSubmitter',
  props: {
    showClear: {
      type: Boolean,
      required: false,
      default: true
    },
    formValid: {
      type: Boolean,
      required: false,
      default: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      membersList: [],
      chosenUser: {}
    }
  },
  computed: {
    ...mapGetters({
      members: 'membersList',
      loggedInUser: 'userData'
    })
  },
  watch: {
    members: function () {
      this.generateFields()
    }
  },
  mounted () {
    setTimeout(() => {
      this.refreshMembers()
      this.generateFields()
    }, 500)
  },
  methods: {
    ...mapActions({
      refreshMembers: 'refreshUserData'
    }),
    generateFields: function () {
      this.membersList = []

      this.members.forEach(member => {
        const memberField = { ...member }

        memberField.callback = () => this.emitPostClick(member)
        memberField.text = 'Submit as ' + member.name

        this.membersList.push(memberField)

        if (Number(member.id) === Number(this.loggedInUser.user_id)) {
          this.chosenUser = memberField
        }
      })
    },
    emitPostClick: function (memberToPostAs) {
      console.log('Post click emitted as', memberToPostAs.display_name)

      this.$emit('onSubmitClick', memberToPostAs)
      // The parent seems to clear the select, so refresh after a little while
      setTimeout(() => {
        this.refreshMembers()
      }, 200)
    },
    emitClearClick: function () {
      this.refreshMembers()
      this.$emit('onClearClick')
    }
  }
}
</script>

<style scoped>

</style>
